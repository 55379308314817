import { ChevronLeft } from '@mui/icons-material'
import { Typography, TypographyProps } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { back } from '@redwoodjs/router'

import Button from '../Button/Button'

type PageTitleProps = TypographyProps & {
  noMarginBottom?: boolean
  subTitle?: string
  showBackButton?: boolean
}
const PageTitle = ({ variant, children, noMarginBottom, subTitle, showBackButton, ...props }: PageTitleProps) => {
  const marginBottom = noMarginBottom ? 0 : 2
  return (
    <Grid container justifyContent="space-between" sx={{ mb: marginBottom }}>
      <Grid>
        <Typography variant={variant || 'h1'} {...props}>
          {children}
        </Typography>
        {subTitle && (
          <Typography variant="h2" style={{ fontSize: '1.3rem' }}>
            {subTitle}
          </Typography>
        )}
      </Grid>
      {showBackButton && (
        <Grid>
          <Button startIcon={<ChevronLeft />} variant="outlined" color="secondary" onClick={back}>
            Back
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default PageTitle
